import { FunctionComponent } from "react";
import { Upload } from "../../../../../types";
import { classNames } from "../../../../../layout/AppLayout";
import { FolderIcon } from "@heroicons/react/outline"


interface Props {
    upload?: Upload
    width: 'full' | 'small'
}

const UploadCard: FunctionComponent<Props> = ({upload, width}) => {
    const isYoutube = upload?.file.includes('youtube.com');
    return upload?.file ? (
        <div className={classNames(
            "bg-white shadow-md rounded-lg p-4",
            width === 'full' ? 'w-full' : 'grid-col-6 md:grid-col-4 lg:grid-col-3',
        )}>
            <div className="flex justify-between">
               {isYoutube ? (
                   <iframe
                       width="100%"
                       height="200"
                       src={upload.file}
                       title={upload.display_name}
                       frameBorder="0"
                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                       allowFullScreen
                   />
               )  : (
                <a href={upload.file}>
                    {upload.thumbnail ? (<img
                        src={upload.thumbnail}
                        alt={upload.display_name}
                        className="w-full h-40 object-cover"
                    />) : (<div>{upload?.display_name} <FolderIcon /> </div>)}
                </a>
               )} 
            </div>
        </div>
    ) : (<div></div>)
}


export default UploadCard;