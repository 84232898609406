import {User} from "../../types";
import {useGetUserQuery} from "../../api/user/api";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {useEffect} from "react";
import {setUser} from "../../store/app-slice";

export const useAuthUser = (): User | undefined => {
  const accessToken = useAppSelector(
    (state: RootState) => state.appState.authToken
  );
  const stateUser = useAppSelector(
    (state: RootState) => state.appState.user
  );
  const dispatch = useAppDispatch()

  const {data: user, error} = useGetUserQuery(null, {skip: !accessToken});

  useEffect(() => {
    if (error) {
      console.error(error);
      dispatch(setUser(null));
    } else {
      dispatch(setUser(user));
    }
  }, [dispatch, user, error]);

  return stateUser;
};
