import { useParams } from "react-router-dom";
import { useGetUploadCategoryQuery } from "../../../../api/upload/api";
import Loader from "../../../../components/Loader";
import Heading from "../../../../components/Heading";
import { Upload } from "../../../../types";
import PageLayout from "../../../../layout/PageLayout";
import UploadCard from "./components/UploadCard";
import { useTranslation } from "react-i18next";




const LibraryDetail = () => {
    const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
    const { id } = useParams();
    const {t} = useTranslation();
    const {data: detail, isLoading} = useGetUploadCategoryQuery({type: 'library', id: id ? parseInt(id) : 0});
    const uploadsCopy =  detail?.uploads && detail?.uploads.length > 0 ? [...detail?.uploads] : [];
    const lastUpload = uploadsCopy.length > 0 ? uploadsCopy.shift() : undefined;

    return (
    <PageLayout imageUrl={imageUrl}>
        {isLoading || !detail ? <Loader /> : (
            <div>
            <Heading size="l" subtitle={detail?.description}>{detail.name}</Heading>


            <div className="mt-6">
                <h3 className="text-lg font-semibold mb-2">{t('forms.sections.userLibrary.lastUpload')}</h3>
                { detail?.uploads?.length === 0 ? (<p>No uploads</p>) : (
                <UploadCard upload={lastUpload} width="full" />
                )}
            </div>
            <div className="grid grid-cols-12 mt-6">
                {uploadsCopy?.map((upload: Upload) => (
                    <UploadCard upload={upload} width="small" />
                ))}
            </div>
            </div>
        )}
    </PageLayout>
    )
}

export default LibraryDetail;