import CourseLayout from "../../../layout/CourseLayout";
import {
    useCourseDetailQuery,
    useDeleteCourseStepAttachmentMutation,
    useEditCourseStepMutation,
    useUpdateCourseStepMutation
} from "../../../api/courses/api";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { resolveErrorMessage } from "../../../utils/errors";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { CourseChapter, CourseStep, CourseStepAttachment } from "../../../types";
import EditStepForm, { EditStepFormData } from "../../components/EditStepForm";
import { CreateOutlined, Download, Delete } from "@mui/icons-material";
import { useAuthUser } from "../../../components/UserState/hooks";
import { IconButton, ListItemIcon, Typography, Button } from "@mui/material";

interface Props {
    edit?: boolean
}

const Course: FC<Props> = ({ edit }) => {
    const { t } = useTranslation()
    const params = useParams();
    const navigate = useNavigate();
    const [editStep] = useEditCourseStepMutation();
    const [deleteAttachment] = useDeleteCourseStepAttachmentMutation();
    const [updateStep] = useUpdateCourseStepMutation();
    const user = useAuthUser();

    let id = 0;
    let stepId = 0;
    try {
        id = parseInt(params.courseId || '0')
        stepId = parseInt(params.stepId || '0')
    } catch (e: any) {
        const error = e.data ? e.data : e
        Sentry.captureException(error);
        toast.error(resolveErrorMessage(error, t("error.ParseParam")))
        navigate('/')
    }

    const { data: course, isFetching: isLoading } = useCourseDetailQuery({ id });

    let selectedStep: CourseStep | undefined
    course?.chapters?.forEach((chapter) => {
        if (selectedStep) return;
        selectedStep = chapter?.steps.find((step) => {
            if (stepId && step.id === stepId) {
                return step
            } else if (!stepId) return step
            return false
        })
    })
    let selectedChapter: CourseChapter | undefined
    let nextStep: CourseStep | undefined

    const findChapterById = (id: number): CourseChapter | undefined => {
        return course?.chapters?.find((chapter) => chapter.id === id)
    }
    if (selectedStep) {
        selectedChapter = findChapterById(selectedStep.course_chapter_id);
        if (selectedChapter) {
            course?.chapters?.filter((chapter) => chapter.order >= selectedChapter!.order).forEach((chapter) => {
                if (nextStep) return
                nextStep = chapter?.steps.find((step) => {
                    const stepChapter = findChapterById(step.course_chapter_id);
                    if (step.order > selectedStep!.order && stepChapter!.id === selectedChapter!.id) {
                        return step
                    } else if (stepChapter!.id !== selectedChapter!.id) {
                        return step
                    } else {
                        return false;
                    }
                });
            })
        }
    }

    if ((stepId === 0 || !stepId) && selectedStep) stepId = selectedStep?.id

    let markComplete = (courseId: number, chapterId: number, id: number, isComplete: boolean) => {
        if (!courseId || !chapterId || !id) return
        updateStep({ courseId, chapterId, id, isComplete }).then((resp: any) => {
            if (resp?.error) {
                Sentry.captureException(resp.error);
                toast.error(resolveErrorMessage(resp.error, t("errors.EditStep")))
            } else {
                if (isComplete && nextStep) {
                    navigate(`/course/${courseId}/${nextStep.id}`)
                    toast.success(t("messages.EditStep"))
                } else if (isComplete && !nextStep) {
                    toast.success(t("messages.CourseCompleted"));
                    navigate('/');
                }
            }
        })
    }

    let submitEditStep = (data: EditStepFormData) => {
        if (course && selectedStep) {
            const haveMain = data.attachments?.find((attachment) => attachment.main)
            if (haveMain) {
                try {
                    selectedStep.attachments.forEach(async (attachment) => {
                        if (selectedStep && attachment.main) {
                            await deleteAttachment({ id: selectedStep.id, attachmentId: attachment.id })
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            }
            return editStep({
                data,
                id: selectedStep.id,
                course_chapter_id: selectedStep.course_chapter_id
            }).then((resp: any) => {
                if (resp?.error) {
                    Sentry.captureException(resp.error);
                    toast.error(resolveErrorMessage(resp.error, t("errors.EditStep")))
                } else {
                    toast.success(t("messages.EditStep"))
                }
            })
        }
        return Promise.resolve()
    }

    const video = selectedStep?.attachments.find((attachment) => attachment.main)

    const attachments = selectedStep?.attachments.filter((attachment) => !attachment.main)
    const removeAttachment = async (attachment: CourseStepAttachment) => {
        try {
            await deleteAttachment({ id: stepId, attachmentId: attachment.id })
        } catch (e) {
            console.log(e)
            toast.error("Chyba pri vymazani suboru")
        }

        toast.success(t("messages.Success"))
    }

    return (
        <CourseLayout course={course} isLoading={isLoading} edit={edit} selectedStep={selectedStep}>
            {(!edit || !stepId) && <div>
                <Typography variant="h3">
                    <>
                        {selectedStep?.name}
                        {selectedStep && course && user?.is_admin &&
                            <span className="float-right">
                                <ListItemIcon>
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation()
                                        navigate(`/course/${course?.id}/${selectedStep?.id}/edit`)
                                    }}>
                                        <CreateOutlined />
                                    </IconButton>
                                </ListItemIcon>
                            </span>
                        }
                    </>
                </Typography>
                <hr className="my-2" />
                {video && <iframe width="100%" height="500" src={video.url}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>}
                <Typography sx={{ mb: 1.5, mt: 2 }} variant="body1"
                    dangerouslySetInnerHTML={{ __html: selectedStep?.content || '' }}>
                </Typography>
                {attachments && attachments.length &&
                    <div>
                        <Typography variant="h4">{t('fields.Attachments')}</Typography>
                        <hr className="my-2" />
                        {attachments.map((attachment) => {
                            return (
                                <span className={(user?.is_admin ? "attachment-item" : '')}>
                                    <a href={attachment.url} target="_blank" rel="noreferrer"
                                        key={`attachment-${attachment.id}`} download>
                                        <Download />
                                    </a>
                                    <button className="remove text-red-800" onClick={() => removeAttachment(attachment)}><Delete /></button>
                                </span>
                            )
                        })}
                    </div>
                }
                {selectedStep && course && (
                    <div className="text-right">
                        <Button
                            onClick={() => selectedStep ? markComplete(course.id, selectedStep.course_chapter_id, selectedStep.id, !selectedStep.isComplete) : null}
                            variant="contained"
                        >
                            {t('forms.actions.Complete')}
                        </Button>
                    </div>
                )
                }
            </div>
            }
            {edit && selectedStep && stepId && <EditStepForm onSubmit={submitEditStep} data={selectedStep} />}
        </CourseLayout >
    )
}

export default Course
