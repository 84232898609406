import Heading from "../../../components/Heading";
import {useTranslation} from "react-i18next";
import PageLayout from "../../../layout/PageLayout";
import Loader from "../../../components/Loader";
import { useGetUploadCategoriesByTypeQuery } from "../../../api/upload/api";
import { classNames } from "../../../layout/AppLayout";
import { ArrowRightIcon } from "@heroicons/react/outline";


// DONE: update api to call get upload categories
// TODO: update use to show categories 
// TODO: add detail view for category and show files // acordian ?? 
const UserLibrary = () => {
  const imageUrl = "/pages/00_-prihlasit-sa-registracia.jpg"
  const {t} = useTranslation();
  const {data: categories, isFetching: isLoadingEvents} = useGetUploadCategoriesByTypeQuery({type: 'library', search: null, order: 'asc', orderBy: 'name', page: 1});


  console.log(categories)
  return (
    <PageLayout imageUrl={imageUrl}>
      <div className="p-4 py-12 max-w-2xl">
        <Heading size="l">{t('forms.sections.userLibrary.Title')}</Heading>
        <Loader isLoading={isLoadingEvents}/>
        <ul className="list-none pl-0">
          {
            categories && categories?.data?.map((category) => (
              <li>
                <div className="flex">
                  <div className="flex-grow">
                    <a 
                    href={`/user/library/${category.id}`}
                    className={classNames(
                        "w-full flex px-4 py-2 bg-blue-500 my-2",
                        "hover:bg-blue-700 font-semibold text-white cursor-pointer",
                        "transition-colors duration-1000 ease-in-out"
                    )}>{category.name} <ArrowRightIcon className="ml-2 w-5" /></a>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </PageLayout>
  )
}

export default UserLibrary
