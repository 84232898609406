import {Menu, Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {useAuthUser} from "../../../components/UserState/hooks";
import UserMenuLink from "../UserMenuLink";
import {useAppDispatch} from "../../../store";
import {resetAppState} from "../../../store/app-slice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const UserMenu = () => {
  const user = useAuthUser();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return user ? (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button
          className="bg-gray-blue-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white -mt-1">

          <img
            className="h-8 w-8 rounded-full"
            src={user?.profile_image_thumb || '/profile-default-image.png'}
            alt=""
          />
          <span className="sr-only">{t('action.OpenUserMenu')}</span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {user.is_admin ?
            (<UserMenuLink linkTo="/admin">
              {t('menu.Admin')}
            </UserMenuLink>) : null
          }
          <UserMenuLink linkTo="/user/profile">
            {t('menu.Profile')}
          </UserMenuLink>
          <UserMenuLink linkTo="/user/change-password">
            {t('menu.ChangePassword')}
          </UserMenuLink>
          <UserMenuLink linkTo="/user/courses">
            {t('menu.Courses')}
          </UserMenuLink>
          <UserMenuLink linkTo="/user/library">
            {t('menu.Library')}
          </UserMenuLink>
          <hr/>
          <UserMenuLink onClick={() => {
            dispatch(resetAppState(null))
            navigate('/')
          }}>
            {t('menu.SignOut')}
          </UserMenuLink>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null
}

export default UserMenu
