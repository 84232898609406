import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PeopleIcon from '@mui/icons-material/People';
import Map from '@mui/icons-material/Map';
import {Article, Web, CalendarToday, Image, PeopleOutline, Book} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const MainListItems = () => {
  const {t} = useTranslation();

  return (
    <div>
      <ListItem button component={Link} to="/admin/pages">
          <ListItemIcon>
            <Web/>
          </ListItemIcon>
          <ListItemText primary={t('admin.sections.Pages')}/>
      </ListItem>
      <ListItem button component={Link} to="/admin/events">
        <ListItemIcon>
          <CalendarToday/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Events')}/>
      </ListItem>
      <ListItem button component={Link} to="/admin/courses">
        <ListItemIcon>
          <CalendarToday/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Courses')}/>
      </ListItem>
    <ListItem button component={Link} to="/admin/library">
        <ListItemIcon>
            <Book/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Library')}/>
    </ListItem>
      <ListItem button>
        <ListItemIcon>
          <Article/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Articles')}/>
      </ListItem>
      <ListItem button component={Link} to="/admin/users">
        <ListItemIcon>
          <PeopleIcon/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Users')}/>
      </ListItem>
      <ListItem button component={Link} to="/admin/sidebar-images">
        <ListItemIcon>
          <Image/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.SidebarImages')}/>
      </ListItem>
      <ListItem button component={Link} to="/admin/guests">
        <ListItemIcon>
          <PeopleOutline/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Guests')}/>
      </ListItem >
    </div>
  );
}

export const SecondaryListItems = () => {
  const {t} = useTranslation();

  return (
    <div>
      <ListSubheader inset>{t('admin.sections.Other')}</ListSubheader>
      <ListItem button>
        <ListItemIcon>
          <Map/>
        </ListItemIcon>
        <ListItemText primary={t('admin.sections.Regions')}/>
      </ListItem>
    </div>
  )
};
