import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../index";
import { Upload, Paginated, AdminListParams, SuccessResponse, UploadResponse, UploadCategory } from '../../types'
import { UploadData, EditUploadData } from "../../admin/components/UploadForm";
import {LibraryCategoryFormData} from "../../admin/components/LibraryCategoryForm";

interface uploadRequest extends AdminListParams {
    type: string,
    category: string,
    size?: number,
    page?: number
}

export const UploadApi = createApi({
    reducerPath: 'upload',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Uploads', 'UploadCategory'],
    endpoints: (build) => ({
        getUploadCategoriesByType: build.query<Paginated<UploadCategory>, { type: string, search: string | null, order: string, orderBy: string, page: number }>({
            query: ({ type }) => ({ url: `/uploads/${type}`, method: 'get' }),
            providesTags: ['UploadCategory']
        }),
        getUploadCategory: build.query<UploadCategory, { type: string, id: number }>({
            query: ({ type, id }) => ({ url: `/uploads/${type}/s/${id}`, method: 'get' }),
            providesTags: ['UploadCategory']
        }),
        deleteUploadCategory: build.mutation<SuccessResponse, { type: string, id: number }>({
            query: ({ type, id }) => ({ url: `/admin/uploads/${type}/${id}`, method: 'delete' }),
            invalidatesTags: ['UploadCategory']
        }),
        getUploads: build.query<Paginated<Upload>, uploadRequest>({
            query: ({ category, type, search, page = 1, size = 10 }) => ({ url: `/admin/uploads/${type}/${category}?page=${page}&size=${size}&search=${search}`, method: 'get' }),
            providesTags: ['Uploads']
        }),
        getUploadDetail: build.query<Upload, { type: string, category: string, id: number }>({
            query: ({ category, type, id }) => ({ url: `/uploads/${type}/${category}/${id}`, method: 'get' }),
            providesTags: ['Uploads']
        }),
        deleteUpload: build.mutation<SuccessResponse, { type: string, category: string, id: number }>({
            query: ({ id, type, category }) => ({ url: `/admin/uploads/${type}/${category}/${id}`, method: 'delete' }),
            invalidatesTags: ['Uploads', 'UploadCategory']
        }),
        createUploadCategory: build.mutation<UploadCategory, {data: LibraryCategoryFormData, type: string}>({
            query: ({ data, type }) => ({ url: `/admin/uploads/${type}`, method: 'post', data: {...data, subPath: data?.name.toLowerCase().replace(' ', '_')} }),
            invalidatesTags: ['UploadCategory']
        }),
        editUploadCategory: build.mutation<UploadCategory, {data: LibraryCategoryFormData, type: string, id: number}>({
            query: ({ data, type, id }) => ({ url: `/admin/uploads/${type}/${id}`, method: 'put', data }),
            invalidatesTags: ['UploadCategory']
        }),
        createUpload: build.mutation<UploadResponse, { data: UploadData, type: string, category: string }>({
            query: ({ data, category, type }) => {
                const formData = new FormData();
                formData.append('file', data.file[0])
                if (data?.video_link) {
                    formData.append('video_link', data.video_link)
                }
                if (data?.description) {
                    formData.append('description', data.description)
                }
                formData.append('display_name', data.display_name)
                if (data?.event_type_id) {
                    formData.append('event_type_id', data?.event_type_id)
                }
                formData.append('order', data?.order || '0')
                console.log(category)
                return ({ url: `/admin/uploads/${type}/${category}`, method: 'post', data: formData })
            },
            invalidatesTags: ['Uploads', 'UploadCategory']
        }),
        editUpload: build.mutation<UploadResponse, { data: EditUploadData, type: string, category: string, id: number }>({
            query: ({ data, category, type, id }) => {
                return ({ url: `/admin/uploads/${type}/${category}/${id}`, method: 'put', data })
            },
            invalidatesTags: ['Uploads']
        })
    })
})

export const { useGetUploadsQuery, useGetUploadCategoryQuery, useEditUploadCategoryMutation, useGetUploadCategoriesByTypeQuery, useDeleteUploadCategoryMutation, useDeleteUploadMutation, useCreateUploadMutation, useCreateUploadCategoryMutation,useGetUploadDetailQuery, useEditUploadMutation } = UploadApi
